import { InformationCircle, QuestionCircle } from '@prenuvo/halo-icon';

import type { NavListProps } from '@/components/NavMenu/NavMenu.types';
import { NAV_OPTIONS } from '@/core/constants';
import type { FormStructureSummary } from '@/store/useFormStructure/useFormStructure.type';
import type { ConditionTemplate, Finding, FindingsByOrgan, RenderedSummary } from '@/types/finding';
import type { FormStructure } from '@/types/formStructure';
import type { Line } from '@/types/transcription';

import type { FindingAttribute, MedicalItem, MissingField } from './utils.types';

export const pcmEncode = (input: Float32Array) => {
  const buffer = new ArrayBuffer(input.length * 2);
  const view = new DataView(buffer);

  for (let i = 0; i < input.length; i++) {
    const s = Math.max(-1, Math.min(1, input[i]));

    view.setInt16(i * 2, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }

  return buffer;
};

export function formatTranscriptionSentence(items: MedicalItem[] | undefined): string {
  if (!items) {
    return '';
  }

  let completeSentence = '';
  const symbols = ['.', ',', '!', '?', ':', ';', ')', '-', '(', '[', ']', '{', '}'];

  for (let i = 0; i < items.length; i++) {
    const { content } = items[i];

    if (content) {
      if (!symbols.includes(content)) {
        completeSentence += ` ${content.toLocaleLowerCase()}`;
      }
    }
  }

  return completeSentence;
}

export const addTranscriptionSymbols = (lines: Line[]) =>
  lines
    .map((line) => line.text)
    .join('')
    .replace(/(^|\b)\s*(open parenthesis|open paren)\.?\s*/gi, '(')
    .replace(/(^|\b)\s*(close parenthesis|close paren)\.?\s*/gi, ') ')
    .replace(/\.\s*\)/g, ')')
    .replace(/(^|\b)\s*(period)\.?\s*/gi, '. ')
    .replace(/(^|\b)\s*(comma)\.?\s*/gi, ', ')
    .replace(/\s{2,}/g, ' ');

export const selectedTextFromTextArea = (textArea: HTMLTextAreaElement) => {
  const start = textArea.selectionStart;
  const end = textArea.selectionEnd;

  return {
    end,
    start,
    textAreaSelected: textArea.value.substring(start, end),
  };
};

export const determineVariant = ({
  error,
  isFinishedFinding,
  isFocused,
  isTranscribing,
  text,
}: {
  error?: boolean;
  isFinishedFinding: boolean;
  isFocused: boolean;
  isTranscribing: boolean;
  text: string;
}) => {
  if (error) {
    return 'error';
  }

  if (isFinishedFinding) {
    return 'loading';
  }

  if (isFocused || isTranscribing || text !== '') {
    return 'focused';
  }

  return 'default';
};

export const transformStructureToNavList = (data: FormStructure): FormStructureSummary => {
  const transformedStructure: NavListProps[] = data?.structure?.map((item) => ({
    key: item.key,
    icon: NAV_OPTIONS[item.key as keyof typeof NAV_OPTIONS] || QuestionCircle,
    progress: null,
    title: item.value,
  }));

  const ReasonForScanOption = {
    key: 'reason_for_scan',
    icon: InformationCircle,
    progress: null,
    title: 'Reason for a scan',
  };

  const favoriteOption = {
    key: 'favorite',
    icon: NAV_OPTIONS.favorite,
    progress: null,
    title: 'Final Impressions',
  };

  const keyImagesOption = {
    key: 'key_images',
    icon: NAV_OPTIONS.key_images,
    progress: null,
    title: 'Key Images',
  };

  transformedStructure?.push(keyImagesOption, favoriteOption);
  transformedStructure?.unshift(ReasonForScanOption);

  return {
    ...data,
    structure: transformedStructure,
  };
};

export const transformSnakeCaseToPascalCase = (str: string) =>
  str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const transformSentenceToSnakeCase = (str: string) =>
  str.trim().toLowerCase().replace(/\s+/g, '_');

export const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear());

  return `${year}-${month}-${day}`;
};

export const convertTextToBulletPoints = (text: string) => {
  if (text && text.includes('\n')) {
    const lines = text.split('\n').filter((line) => line.trim() !== '');
    const bulletPoints = lines.map(
      (line) =>
        `<li data-list="bullet"><span class="ql-ui" contenteditable="false"></span>${line.trim()}</li>`,
    );

    return `<ol>${bulletPoints.join('')}</ol>`;
  }

  return text;
};

export const findObjectByKeyValue = <T, K extends keyof T>(
  array: T[],
  key: K,
  value: T[K],
): T | undefined => array.find((obj) => obj[key] === value);

export const groupFindingsByOrgan = (data: RenderedSummary[]): { [key: string]: Finding[] } => {
  const groupedData: { [key: string]: Finding[] } = {};

  data.forEach((item) => {
    if (item.reportFindings.length === 0) {
      return;
    }

    const organ = item?.reportFindings[0]?.template?.organ || '';

    if (!groupedData[organ]) {
      groupedData[organ] = [];
    }

    const renderedSummaryData: Finding = {
      conditionTemplate: {
        condition: item.reportFindings[0].template?.condition,
        conditionDetail: item.reportFindings[0].template?.conditionDetail,
        normalStatementOutcome: item.reportFindings[0].template?.normalStatementOutcome,
        organ,
        uuid: item.reportFindings[0].template?.uuid,
      },
      renderedSummary: {
        actionDetails: item.actionDetails,
        actionSummary: item.actionSummary,
        csdScore: item.csdScore,
        findingDetails: item.findingDetails,
        findingSummary: item.findingSummary,
        newFinding: item.newFinding,
        oncoradsScore: item.oncoradsScore,
        reportFindings: item.reportFindings,
        reportFindingsUuids: item.reportFindingsUuids,
        reportUuid: item.reportUuid,
        status: '',
        uuid: item.uuid,
      },
    };

    groupedData[organ].push(renderedSummaryData);
  });

  return groupedData;
};

export const findMissingRequiredDetails = (groupedData: {
  [key: string]: Finding[];
}): MissingField => {
  const result: MissingField = {};

  Object.keys(groupedData).forEach((key) => {
    const organData = groupedData[key];

    organData.forEach((item, findingIndex) => {
      const { conditionDetail = [] } = item.conditionTemplate;
      const { reportFindings } = item.renderedSummary;
      const requiredFields = conditionDetail.filter((detail) => detail.required);

      if (requiredFields.length === 0) {
        return;
      }

      reportFindings.forEach((reportFinding, observationIndex) => {
        const { conditionDetail: conditionDetailData } = reportFinding;

        const isRequiredFieldsPresent = requiredFields.every((field) =>
          conditionDetailData.some((detail) => detail.name === field.name && detail.value !== null),
        );

        if (!isRequiredFieldsPresent) {
          result[key] = { error: !isRequiredFieldsPresent, findingIndex, observationIndex };
        }
      });
    });
  });

  return result;
};

export const getOrgansWithHighScores = (data: {
  [key: string]: Finding[];
}): { [key: string]: boolean } => {
  const result: { [key: string]: boolean } = {};

  Object.keys(data).forEach((key) => {
    const organData = data[key];

    organData.forEach((item) => {
      const { csdScore, oncoradsScore } = item.renderedSummary;

      if (oncoradsScore === 4 || oncoradsScore === 5 || csdScore === 4 || csdScore === 5) {
        result[key] = true;
      }
    });
  });

  return result;
};

export const replaceConditionWithValue = (inputText: string): string => {
  const regex = /<{name: "[^"]+", value: "([^"]+)", uuid: "[^"]+"}>/g;

  return inputText.replace(regex, (_, p1) => p1);
};

export const transformSentenceToKebabCase = (str: string) =>
  str
    .trim()
    .toLowerCase()
    .replace(/[\s,.&]+/g, '-')
    .replace(/[^a-z0-9-]/g, '');

export const generateListFromString = (value: string): { className: string; content: string }[] => {
  const liContent = value.match(/<li[^>]*>(.*?)(?=<li|$)/g);

  if (liContent === null) {
    return [{ className: '', content: value }];
  }

  return liContent.map((item) => {
    const classNameMatch = item.match(/class="([^"]*)"/);
    const className = classNameMatch ? classNameMatch[1] : '';
    const content = item
      .replace(/<li class="[^"]*">|<li>|<\/li>/g, '')
      .replace(/<b>/g, '<b class="text-neutral-200 dark:text-neutral-200">')
      .trim();

    return { className, content };
  });
};

export const calculateAge = (dob: string): number => {
  const today = new Date();
  const dobDate = new Date(dob);
  let age = today.getFullYear() - dobDate.getFullYear();
  const monthDiff = today.getMonth() - dobDate.getMonth();
  const dayDiff = today.getDate() - dobDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
};

export const parseFindingAttributes = (findingDetails: string): FindingAttribute[] => {
  const regex =
    /<\{name\s*:\s*"(.*?)",\s*value\s*:\s*(?:"(.*?)"|null)(?:,\s*uuid\s*:\s*"(.*?)")?\}>/g;
  const placeholders: FindingAttribute[] = [];
  let match: null | RegExpExecArray;

  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(findingDetails)) !== null) {
    const [fullMatch, name, value, uuid] = match;

    placeholders.push({
      endIndex: match.index + fullMatch.length,
      name,
      startIndex: match.index,
      uuid,
      value: value === 'None' ? null : value || null,
    });
  }

  return placeholders;
};

export const formatEditorString = (note: string) => note?.replace(/<[^>]*>?/g, '').trim();

export const parseOrganTitle = (organ: string) => {
  const organName = organ.split('_').join(' ');

  return organName.charAt(0).toUpperCase() + organName.slice(1);
};

export const getConditionTemplates = (
  findings: FindingsByOrgan,
): {
  [key: string]: ConditionTemplate[];
} =>
  Object.entries(findings).reduce<{ [key: string]: ConditionTemplate[] }>(
    (acc, [organ, organFindings]) => {
      acc[organ] = organFindings.map((finding) => finding.conditionTemplate as ConditionTemplate);

      return acc;
    },
    {},
  );
