import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Typography, useToaster } from '@prenuvo/halo-web';

import { Header, Loading, NavMenu } from '@/components';
import {
  usePatientStudies,
  useReport,
  useScanDetails,
  useStudyMedicalHistory,
} from '@/hooks/queries';
import { useFormStructure as useFormStructureQuery } from '@/hooks/queries/useFormStructure/useFormStructure';
import { useMedicalHistory } from '@/hooks/queries/useMedicalHistory/useMedicalHistory';
import { useFinding, useHiStudy } from '@/store';
import { useOrganValidation } from '@/store/useOrganValidation/useOrganValidation';
import { OrganValidationStatus } from '@/store/useOrganValidation/useOrganValidation.types';
import { useReport as useReportStore } from '@/store/useReport/useReport';
import { Finding } from '@/types/finding';
import { findMissingRequiredDetails, getOrgansWithHighScores } from '@/utils/utils';

import { ErrorWithoutBackground } from '../assets/icons';

export function MainLayout() {
  const navigate = useNavigate();
  const { id: studyId, organ } = useParams();
  const location = useLocation();

  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const [selectedLink, setSelectedLink] = useState<string>('');
  const { scanDetails } = useHiStudy();
  const { patientID, scanDate } = scanDetails;
  const { isLoading: isLoadingReport } = useReport();
  const { formStructure, isLoading: isLoadingFormStructure } = useFormStructureQuery(studyId);
  const { isLoading: isLoadingScanDetails } = useScanDetails(studyId);
  const { isLoading: isLoadingMedicalHistory } = useMedicalHistory(studyId);
  const { isLoading: isLoadingStudyMedicalHistory } = useStudyMedicalHistory(studyId);
  const { isLoading: isLoadingPatientStudies } = usePatientStudies(patientID, studyId, scanDate);
  const { findings } = useFinding();
  const { getStatus, organs, setStatus } = useOrganValidation();
  const { addToast } = useToaster();
  const { report } = useReport();
  const toastRef = useRef<HTMLSpanElement>(null);
  const { setPreviewMode } = useReportStore();

  const setOrganStateHandler = (data: { [key: string]: Finding[] }) => {
    Object.keys(data).forEach((organData) => {
      setStatus(organData, { done: true, error: false, warning: false });
    });

    const missingRequiredFields = findMissingRequiredDetails(data);

    Object.keys(missingRequiredFields).forEach((organData) => {
      if (missingRequiredFields[organData]) {
        const status = getStatus(organData)!;

        setStatus(organData, { ...status, error: true });
      }
    });

    const highScoreOrganData = getOrgansWithHighScores(data);

    Object.keys(highScoreOrganData).forEach((organData) => {
      if (highScoreOrganData[organData]) {
        const status = getStatus(organData)!;

        setStatus(organData, { ...status, warning: true });
      }
    });
  };

  const initializeOrganState = () => {
    formStructure?.structure.forEach((organData) => {
      if (organData.key === 'reason_for_scan') {
        setStatus(organData.key, { done: true, error: false, warning: false });
      } else {
        const currentOrganState = getStatus(organData.key);

        if (!currentOrganState) {
          setStatus(organData.key, { done: false, error: false, warning: false });
        } else {
          setStatus(organData.key, { ...currentOrganState });
        }
      }
    });

    if (report?.finalImpressions) {
      setStatus('favorite', { done: true, error: false, warning: false });
    }

    setOrganStateHandler(findings);
  };

  useEffect(() => {
    if (Object.keys(findings).length > 0 || formStructure?.structure) {
      initializeOrganState();
    }
  }, [findings, formStructure?.structure]);

  useEffect(() => {
    const pathMap = {
      'entry/favorite': 'favorite',
      'entry/key-images': 'key_images',
    };

    const selectedLinkValue = organ
      ? organ.replace(/-/g, '_')
      : Object.entries(pathMap).find(([path]) => location.pathname.includes(path))?.[1] ||
        'reason_for_scan';

    setSelectedLink(selectedLinkValue);
  }, [organ, location]);

  const onReviewSignOrganValidation = (organsData: Record<string, OrganValidationStatus>) =>
    Object.keys(organsData).every((organObj) => organs[organObj].error === false);

  const handleIncompleteOrgans = () => {
    Object.entries(organs).forEach(([organData, status]) => {
      if (!status.done && organData === 'favorite' && !report?.finalImpressions) {
        setStatus(organData, { ...status, error: true });
      }
    });
  };

  const toastHandler = () => {
    if (toastRef.current) {
      return;
    }

    addToast({
      className: {
        root: 'w-[300px] ml-auto',
      },
      description: {
        children: (
          <span className="flex flex-row gap-4 p-4" ref={toastRef}>
            <img alt="missing input" src={ErrorWithoutBackground} />
            <Typography className="text-red-500 dark:text-red-500">
              Fill out required fields <br /> before proceeding
            </Typography>
          </span>
        ),
      },
      duration: 3000,
      variant: 'error',
    });
  };

  const handleLinkSelect = (link: string) => {
    setSelectedLink(link);

    const linkMap = {
      favorite: `/reporting/${studyId}/entry/favorite`,
      key_images: `/reporting/${studyId}/entry/key-images`,
      reason_for_scan: `/reporting/${studyId}/entry/reason-for-scan`,
    };

    const path =
      linkMap[link as keyof typeof linkMap] ||
      `/reporting/${studyId}/entry/organ/${link.replace(/_/g, '-')}`;

    navigate(path);
  };

  const navigateToPreview = () => {
    if (!onReviewSignOrganValidation(organs)) {
      handleIncompleteOrgans();
      toastHandler();

      const errorOrgan =
        formStructure?.structure &&
        formStructure?.structure.find(
          (organData) => organs[organData.key] && organs[organData.key].error,
        );

      if (errorOrgan) {
        handleLinkSelect(errorOrgan.key);
      }

      return;
    }

    setPreviewMode(true);
    navigate(`/reporting/${studyId}/preview`);
  };

  const toggleSideMenuHandler = () => {
    setIsNavCollapsed((prevState) => !prevState);
  };

  if (
    isLoadingReport ||
    isLoadingPatientStudies ||
    isLoadingFormStructure ||
    isLoadingScanDetails ||
    isLoadingMedicalHistory ||
    isLoadingStudyMedicalHistory
  ) {
    return <Loading />;
  }

  return (
    <div className="flex h-screen flex-col">
      <Header />
      <div className="flex h-[calc(100%-5rem)] p-4 pb-0 pr-0">
        <NavMenu
          isCollapsed={isNavCollapsed}
          links={formStructure?.structure || []}
          maxSize="202"
          minSize="80"
          onLinkSelect={handleLinkSelect}
          onReviewClick={navigateToPreview}
          onToggleSideMenu={toggleSideMenuHandler}
          selectedLinkValue={selectedLink}
        />
        <div className="custom-scrollbar relative flex-1 overflow-y-auto ">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
