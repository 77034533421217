/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { MainLayout } from '@/Layouts';
import { Report } from '@/pages/Report/Report';
import { Loadable } from '@/utils/Loadable';

import { AuthGuard } from '../guards/authGuard/AuthGuard';

const ReasonForScan = Loadable(
  lazy(() =>
    import('../pages/ReasonForScan').then((module) => ({ default: module.ReasonForScan })),
  ),
);

const FinalImpression = Loadable(
  lazy(() =>
    import('../pages/FinalImpression').then((module) => ({ default: module.FinalImpression })),
  ),
);

const KeyImages = Loadable(
  lazy(() => import('../pages/KeyImages').then((module) => ({ default: module.KeyImages }))),
);

const Organ = Loadable(
  lazy(() => import('../pages/Organ').then((module) => ({ default: module.Organ }))),
);

const NotFound = Loadable(
  lazy(() => import('../pages/NotFound').then((module) => ({ default: module.NotFoundPage }))),
);

const NoAccess = Loadable(
  lazy(() => import('../pages/NoAccess').then((module) => ({ default: module.NoAccessPage }))),
);

function DefaultStudyWrapper({ children }: { children: React.ReactNode }) {
  const { id } = useParams();

  if (!id) {
    return <Navigate replace to="/404" />;
  }

  return children;
}

export const routes = [
  {
    children: [
      {
        element: <Navigate replace to="entry/reason-for-scan" />,
        index: true,
      },
      {
        element: <ReasonForScan />,
        path: 'entry/reason-for-scan',
      },
      {
        element: <Organ />,
        path: 'entry/organ/:organ',
      },
      {
        element: <FinalImpression />,
        path: 'entry/favorite',
      },
      {
        element: <KeyImages />,
        path: 'entry/key-images',
      },
    ],
    element: (
      <AuthGuard>
        <DefaultStudyWrapper>
          <MainLayout />
        </DefaultStudyWrapper>
      </AuthGuard>
    ),
    path: '/reporting/:id',
  },
  {
    element: (
      <AuthGuard>
        <Report />
      </AuthGuard>
    ),
    path: '/reporting/:id/preview',
  },
  {
    element: <Navigate replace to="/404" />,
    path: '*',
  },
  {
    element: <NotFound />,
    path: '404',
  },
  {
    element: <NoAccess />,
    path: '401',
  },
];
