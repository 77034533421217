import {
  AdrenalGlands,
  Ankles,
  Bladder,
  Bowel,
  Brain,
  Breast,
  Esophagus,
  Gallbladder,
  Heart,
  Image,
  InformationCircle,
  Knees,
  Liver,
  Lungs,
  Neck,
  Pancreas,
  Pelvis,
  Prostate,
  SacroliacJoints,
  Shoulders,
  Sinus,
  Skleleton,
  Spine,
  Spleen,
  Star,
  Stomach,
  Uterus,
} from '@prenuvo/halo-icon';

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export const NAV_OPTIONS = {
  adrenals: AdrenalGlands,
  ankles: Ankles,
  bladder_and_ureters: Bladder,
  bony_skeleton: Skleleton,
  bowel: Bowel,
  brain: Brain,
  breasts: Breast,
  esophagus: Esophagus,
  favorite: Star,
  gallbladder_and_biliary_system: Gallbladder,
  heart_and_great_vessels: Heart,
  key_images: Image,
  kidneys: '',
  knees: Knees,
  liver: Liver,
  lungs_and_mediastinum: Lungs,
  neck: Neck,
  ovaries: '',
  pancreas: Pancreas,
  pelvis_and_hips: Pelvis,
  prostate: Prostate,
  reason_for_scan: InformationCircle,
  sacroiliac_joints: SacroliacJoints,
  shoulders: Shoulders,
  sinuses_and_mastoids: Sinus,
  spine: Spine,
  spleen: Spleen,
  stomach: Stomach,
  uterus: Uterus,
};

export const ORGANS_HISTORY_MAP = {
  Adrenals: 'adrenals',
  Ankles: 'ankles',
  Bladder: 'bladder_and_ureters',
  BonySkeleton: 'bony_skeleton',
  BowelAndAbdomenPelvicLymphNodes: 'bowel',
  Brain: 'brain',
  Breasts: 'breasts',
  Esophagus: 'esophagus',
  GallbladderAndBiliarySystem: 'gallbladder_and_biliary_system',
  HeartAndGreatVessels: 'heart_and_great_vessels',
  Kidneys: 'kidneys',
  Knees: 'knees',
  Liver: 'liver',
  LungsAndMediastinum: 'lungs',
  Ovaries: 'ovaries',
  Pancreas: 'pancreas',
  PelvisAndHips: 'hips',
  Prostate: 'prostate',
  Shoulders: 'shoulder',
  SinusesAndMastoids: 'sinuses',
  SoftTissueNeck: 'neck',
  Spine: 'spine',
  Stomach: 'stomach',
  Uterus: 'uterus',
};
