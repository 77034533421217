import { create } from 'zustand';

import { ConditionGroup, ConditionsData, ConditionsState } from './useConditionsStore.type';

export const useConditionsStore = create<ConditionsState>((set, get) => ({
  conditions: {},
  getConditions: (organ: string) => get().conditions[organ] || [],
  setConditons: (organ: string, conditions: ConditionsData[]) => {
    const categorizedConditions = conditions.reduce(
      (acc, item) => {
        if (item.condition.startsWith('default_question')) {
          acc.default.push(item);
        } else {
          acc.common.push(item);
        }

        return acc;
      },
      { common: [], default: [] } as ConditionGroup,
    );

    set((state) => ({
      conditions: {
        ...state.conditions,
        [organ]: {
          common: [...(state.conditions[organ]?.common ?? []), ...categorizedConditions.common],
          default: [...(state.conditions[organ]?.default ?? []), ...categorizedConditions.default],
        },
      },
    }));
  },
}));
